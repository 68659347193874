import React, { Component } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { getPageFAQ } from '../../services/UserServices';
import Loading from '../../components/loading/Loading';
import { connect } from 'react-redux';
import './styles.scss';
import faq_image from '../../assets/images/faq_image.png';
import faq_image2 from '../../assets/images/faq_image2.jpeg';
import { ContactUs } from '../../components/home/ContactUs';

class FAQ extends Component {
  state = {
    head: '',
    items: [],
    loading: false,
    title: 'FAQ',
  };

  componentDidMount = async () => {
    let title = this.state.title;
    if (this.props.configAccount.config?.title_tabs) {
      title = this.props.configAccount.config.title_tabs + ' - ' + title;
    }
    document.title = title;
    try {
      await this.setState({ loading: true });
      let response = await getPageFAQ();
      let { head, items } = response.data.data;
      await this.setState({
        head: head.dynamic_seccion,
        items: items,
        loading: false,
      });
    } catch (error) {
      await this.setState({ loading: false });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.configAccount.config !== this.props.configAccount.config) {
      if (this.props.configAccount.config?.title_tabs !== null) {
        document.title =
          this.props.configAccount.config.title_tabs + ' - ' + this.state.title;
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading && <Loading />}
        <div className='container section faq'>
          <div className='row'>
            <div className="col-12 mb-5">
              <div className="text-center d-flex flex-row mt-5">
                <span className='rounded-pill mr-4 border-title-third text-primary-background' ></span>
                <h1 className='dynamic-title font-weight-bold'>
                  <span className='text-primary-background'>PREGUNTAS</span>&nbsp;
                  <span className='text-black'>FRECUENTES</span>
                </h1>
              </div>
            </div>
          </div>
          <div className='row faq-accordion'>
            <div className='col-md-8 col-12'>
              <ul className='accordion'>
                <Accordion allowZeroExpanded={true}>
                  {this.state.items.map((item) => (
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{item.title}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </ul>
            </div>
            <div className='col-md-4 col-12 text-center'>

              {
                window.socket_io_customer === 'remates_bonavista' ?
                  <img src={faq_image2} alt='faq' />
                  : <img className='pt-5' src={faq_image} alt='faq' />
              }
            </div>
          </div>
        </div>
        <ContactUs />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  configAccount: state.accountReducer,
});

export default connect(mapStateToProps, null)(FAQ);
