import axios from 'axios';
import { Constants } from '../Constants';

export const getAllAuctions = async (pageCompleted) => {
    try {
        return await axios.get(`${Constants.BASE_URL}public/auctions?pageCompleted=${pageCompleted}`);
    } catch (error) {
        throw error;
    }
}

export const getAllAuctionsInPerson = async (data) => {
    try {
        return await axios.get(`${Constants.BASE_URL}public/auctionsInPerson`);
    } catch (error) {
        throw error;
    }
}

export const getAuction = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}public/auctions/${data.id}?interval=${data.interval}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getAuctionInPerson = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}public/auctionsInPerson/${data.id}?interval=${data.interval}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getAuctionInProgress = async () => {
    try {
        return await axios.get(`${Constants.BASE_URL}public/getAuctionInProgress`);
    } catch (error) {
        throw error;
    }
}

export const getAuctionInPersonInProgress = async () => {
    try {
        return await axios.get(`${Constants.BASE_URL}public/getAuctionInPersonInProgress`);
    } catch (error) {
        throw error;
    }
}

export const getFavorites = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(
            `${Constants.BASE_URL}myaccount/favorites?auctionId=${data.auctionId}`,
            httpOptions
        );
    } catch (error) {
        throw error;
    }
}