import React, { useEffect, useState } from 'react';
import './styles.scss';
import BannerSlider from '../../components/home/BannerSlider';
import ItemAuction from './item-auction';
import { useAuction } from '../../store/auction';
import { Constants } from '../../Constants';
import moment from 'moment';
import { connect } from 'react-redux';
import Login from '../Login';
import groupImg from '../../assets/images/group.png';
import groupImg2 from '../../assets/images/group2.png';
import { userLoginWithCode } from '../../services/UserServices';
import { userSetStorageAction } from '../../redux/actions/UserActions';
import { toast } from 'react-toastify'
import { ContactUs } from '../../components/home/ContactUs';
import Loading from '../../components/loading/Loading';
import GoogleMap from '../../components/google-maps/GoogleMap';
import PaginationCustom from '../../components/Shared/pagination/Pagination';

const Home = (props) => {
  const { accountProps } = props
  toast.configure();
  const { sessionProps, setLocalStorage } = props;
  const auctionStore = useAuction();
  const [intervalId, setIntervalId] = useState(null);
  const [loginCode, setLoginCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [completedPage, setCompletedPage] = useState(1);
  const [completedPages, setCompletedPages] = useState(1);

  const [auctionLists, setAuctionLists] = useState({
    inProgress: [],
    completed: [],
    pending: [],
    presencials: []
  });

  useEffect(() => {
    document.title = 'Inicio'
  }, [])

  useEffect(() => {
    if (!intervalId) {
      auctionStore.getAllAuctions(completedPage);
      let id = setInterval(() => {
        auctionStore.getAllAuctions(completedPage);
      }, 60000);
      setIntervalId(id);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  useEffect(() => {
    console.log('accountProps', accountProps);
    window.scrollTo(0, 0);
    if (accountProps?.config?.title_tabs) {
      document.title = accountProps.config.title_tabs + ' - ' + document.title;
    }
  }, [accountProps])

  useEffect(() => {
    if (null != auctionStore.auctions) {
      setAuctionLists({
        ...auctionLists,
        inProgress: auctionStore.auctions.data?.inProgress || [],
        completed: auctionStore.auctions.data?.finished?.auctions || [],
        pending: auctionStore.auctions.data?.pending || [],
        presencials: auctionStore.auctions.data?.presencials || []
      });
      setCompletedPages(auctionStore.auctions.data?.finished?.pages || false);
    }
  }, [auctionStore.auctions]);

  useEffect(() => {
  }, [auctionLists]);

  const handleChange = event => {
    const { value } = event.target;
    setLoginCode(value);
  }

  const loginWithCode = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      let response = await userLoginWithCode(loginCode);
      setLoading(false);
      setLocalStorage(response.data.data);
    } catch (error) {
      setLoading(false);
      let msg = 'Ocurrió un error al procesar la solicitud, reintentar';
      if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
        msg = error.response.data.message;
      }
      toast.error(msg, {
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnHover: false,
        duration: 10000
      });
    }
  }

  const getImageUrl = (image) => {
    if (image && image.includes(Constants.BASE_URL_IMAGES)) {
      return image;
    }
    return `${Constants.BASE_URL}${image}`;
  }

  const TitleStatus = ({ title1, title2 }) => {
    if (window.socket_io_customer === 'remates_bonavista') {
      return (
        <div className='mb-5 d-flex'>
          <div className='col-12 text-center'>
            <h1 className='text-primary-background font-weight-bold'>{title1} {title2}</h1>
          </div>
        </div>
      )
    }

    return (
      <div className='mb-5 d-flex'>
        <span className='rounded-pill border-title text-primary-background' ></span>
        <div className='col-12 ml-1'>
          <h1 className='text-primary-background font-weight-bold'>{title1}</h1>
          <h3 className='text-black font-weight-bold'>{title2}</h3>
        </div>
      </div>
    )
  }

  const onPrev = () => {
    setCompletedPage(completedPage - 1);
    auctionStore.getAllAuctions(completedPage - 1);
    // scroll to completed-auctions div
    const element = document.getElementById('completed-auctions');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const onNext = () => {
    setCompletedPage(completedPage + 1);
    auctionStore.getAllAuctions(completedPage + 1);
    // scroll to completed-auctions div
    const element = document.getElementById('completed-auctions');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const onPage = (page) => {
    setCompletedPage(page);
    auctionStore.getAllAuctions(page);
    // scroll to completed-auctions div
    const element = document.getElementById('completed-auctions');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  let isWhiteBackground = true;
  const backgroundDefault = window.socket_io_customer === 'remates_bonavista' ? 'background-grey' : 'background-white';
  const colDefault = window.socket_io_customer === 'remates_bonavista' ? 'col-md-4' : 'col-md-4';
  return (
    <div className='home-box'>
      <BannerSlider />
      {loading && <Loading />}

      {/*
        sessionProps.account !== null ?
          <div className='container' style={{ marginTop: '4rem' }}>
            <img src={thanks} alt="" style={{ width: '100%' }} />
          </div>
          : null*/
      }

      {
        window.socket_io_customer === 'de_remate_ofertas' ? (
          <div className="col-12 p-0 map-without-shipping">
            <h4>¡Atención! No realizamos envíos de mercadería, los lotes deben ser retirados en nuestro local</h4>
            {
              accountProps.config?.contact_map_lat &&
              <GoogleMap
                lat={accountProps.config.contact_map_lat}
                lng={accountProps.config.contact_map_lng}
              />
            }
          </div>
        ) : null
      }

      <section className='light' id="auctions-list">
        {0 < auctionLists.inProgress.length && (
          <div className={`pt-5 pb-5 ${isWhiteBackground ? `${backgroundDefault}` : ''}`}>
            <div className={`box container `}>
              <TitleStatus title1='SUBASTAS' title2='EN PROCESO' />
              <div className='row'>
                {auctionLists.inProgress.map((item) => (
                  <div key={item.id} className={`col-12 ${colDefault} mt-4 d-flex`}>
                    <ItemAuction
                      name={item.title}
                      auctionNumber={item.id}
                      dateFrom={`${moment(item.date_from).format(
                        'dddd D'
                      )} de ${moment(item.date_from).format('MMMM')}, ${moment(
                        item.date_from
                      ).format('HH:mm')}h.`}
                      dateTo={`${moment(item.date_to).format(
                        'dddd D'
                      )} de ${moment(item.date_to).format('MMMM')}, ${moment(
                        item.date_to
                      ).format('HH:mm')}h.`}
                      img={getImageUrl(item.image)}
                      imgMobile={item.imageMobile ? getImageUrl(item.imageMobile) : null}
                      lotsCount={item.count_lot}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {0 < auctionLists.inProgress.length ? isWhiteBackground = !isWhiteBackground : null}

        {0 < auctionLists.presencials.length && (
          <div className={`pt-5 pb-5 ${isWhiteBackground ? `${backgroundDefault}` : ''}`}>
            <div className={`box container `}>
              <TitleStatus title1='SUBASTAS' title2='PRESENCIALES' />
              <div className='row'>
                {auctionLists.presencials.map((item) => (
                  <div key={item.id} className={`col-12 ${colDefault} mt-4 d-flex`}>
                    <ItemAuction
                      name={item.title}
                      presencial={true}
                      auctionNumber={item.id}
                      dateFrom={item.date_from ? `${moment(item.date_from).format(
                        'dddd D'
                      )} de ${moment(item.date_from).format('MMMM')}, ${moment(
                        item.date_from
                      ).format('HH:mm')}h.` : 'Próximamente'}
                      dateTo={`${moment(item.date_to).format(
                        'dddd D'
                      )} de ${moment(item.date_to).format('MMMM')}, ${moment(
                        item.date_to
                      ).format('HH:mm')}h.`}
                      img={getImageUrl(item.image)}
                      lotsCount={item.count_lot}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {0 < auctionLists.presencials.length ? isWhiteBackground = !isWhiteBackground : null}

        {0 < auctionLists.pending.length && (
          <div className={`pt-5 pb-5 ${isWhiteBackground ? `${backgroundDefault}` : ''}`}>
            <div className={`box container `}>
              <TitleStatus title1='SUBASTAS' title2='POR INICIAR' />
              <div className='row'>
                {auctionLists.pending.map((item) => (
                  <div className={`${colDefault} col-sm-12 mt-4 d-flex`}>
                    <ItemAuction
                      name={item.title}
                      auctionNumber={item.id}
                      dateFrom={`${moment(item.date_from).format(
                        'dddd D'
                      )} de ${moment(item.date_from).format('MMMM')}, ${moment(
                        item.date_from
                      ).format('HH:mm')}h.`}
                      img={getImageUrl(item.image)}
                      imgMobile={item.imageMobile ? getImageUrl(item.imageMobile) : null}
                      lotsCount={item.count_lot}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {0 < auctionLists.pending.length ? isWhiteBackground = !isWhiteBackground : null}

        {0 < auctionLists.completed.length && (
          <div id="completed-auctions" className={`pt-5 pb-5 ${isWhiteBackground ? `${backgroundDefault}` : ''}`}>
            <div className='box container'>
              <TitleStatus title1='SUBASTAS' title2='FINALIZADAS' />
              <div className='row'>
                {auctionLists.completed.map((item) => (
                  <div key={item.id} className={`${colDefault} col-12 mb-5 d-flex`}>
                    <ItemAuction
                      name={item.title}
                      auctionNumber={item.id}
                      dateTo={`${moment(item.date_to).format(
                        'dddd D'
                      )} de ${moment(item.date_to).format('MMMM')}, ${moment(
                        item.date_to
                      ).format('HH:mm')}h.`}
                      img={getImageUrl(item.image)}
                      imgMobile={item.imageMobile ? getImageUrl(item.imageMobile) : null}
                      lotsCount={item.count_lot}
                      type='column'
                      finished
                      presencial={item.auction_type === null}
                    />
                  </div>
                ))}
              </div>
            </div>
            <PaginationCustom page={completedPage} pages={completedPages}
              onPrev={onPrev} onNext={onNext} onPage={onPage} />
          </div>
        )}
        {0 < auctionLists.completed.length ? isWhiteBackground = !isWhiteBackground : null}

        {
          sessionProps.account === null &&
          <div className={`pt-5 pb-5 ${isWhiteBackground ? 'background-white' : ''}`}>
            <div id="access_code" className={`container align-items-center ${window.socket_io_customer}`}>
              <div className="row text-center">
                <div className={`${window.socket_io_customer === 'remates_bonavista' ? 'col-md-8 white-background' : 'col-md-5'} col-12`}>
                  <img className='w-100' src={window.socket_io_customer === 'remates_bonavista' ? groupImg2 : groupImg} alt="imagen group"></img>
                </div>
                {
                  window.socket_io_customer !== 'remates_bonavista' ?
                    <div className='col-md-1 col-12'></div>
                    : null
                }
                <div className='col-md-4 col-12 d-flex justify-content-center align-items-center background-white'>
                  <span style={{ borderRadius: '4%' }} className='text-black'>
                    <p style={{ width: '100%', marginBottom: '0.2rem', fontSize: '18px', fontWeight: 'bold' }}>
                      Si estas registrado y tienes inconvenientes para ingresar, solicite un código de acceso directo
                    </p>
                    <br />
                    <p className='mb-4 font-weight-bold'>
                      ¿Ya tienes un código de acceso?
                    </p>
                    <input className='form-control rounded-pill mb-3' style={{ width: '100%', textAlign: 'center' }} placeholder="Ingrese su código de acceso" name="loginCode"
                      value={loginCode} onChange={handleChange} required />
                    <button type="button" onClick={loginWithCode} style={{ width: '100%', marginTop: '0.5rem' }} className="text-white primary-background btn rounded-pill font-weight-bold">Ingresar</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        }
        {sessionProps.account === null ? isWhiteBackground = !isWhiteBackground : null}
      </section>

      <ContactUs />

      <section className='light'>
        {
          sessionProps.account === null ? (
            <div className='box container'>
              <div className='row'>
                <div className='col-12'>
                  <Login props={props} isInHome />
                </div>
              </div>
            </div>
          ) : null
        }
      </section >
    </div >
  );
};


const mapStateToProps = (state) => ({
  sessionProps: state.userReducer,
  accountProps: state.accountReducer,
});

const mapDispatchToProps = (dispatch) => ({
  setLocalStorage: (data) => {
    dispatch(userSetStorageAction(data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);
