import React from 'react';
import './Loading.scss';
import logo from '../../assets/images/spinner.gif'
import logo2 from '../../assets/images/spinner-2.gif'

const Loading = () => {
    return (
        <div className="loading-container">
            <div className="loading-content">
                <img src={window.socket_io_customer === 'remates_bonavista' ? logo2 : logo} className="image" alt="" />
            </div>
        </div>
    );
};

export default Loading;