import React, { Component } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { getPageServices } from '../../services/UserServices';
import Loading from '../../components/loading/Loading';
import { connect } from 'react-redux';
import './styles.scss';
import { ContactUs } from '../../components/home/ContactUs';

class Services extends Component {
    state = {
        head: '',
        items: [],
        loading: false,
        title: 'Comprar - Vender',
    };

    componentDidMount = async () => {
        let title = this.state.title;
        if (this.props.configAccount.config?.title_tabs) {
            title = this.props.configAccount.config.title_tabs + ' - ' + title;
        }
        document.title = title;
        try {
            await this.setState({ loading: true });
            let response = await getPageServices();
            let { head, items } = response.data.data;
            await this.setState({
                head: head.dynamic_seccion,
                items: items,
                loading: false,
            });
        } catch (error) {
            await this.setState({ loading: false });
        }
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.configAccount.config !== this.props.configAccount.config) {
            if (this.props.configAccount.config?.title_tabs !== null) {
                document.title =
                    this.props.configAccount.config.title_tabs + ' - ' + this.state.title;
            }
        }
    };

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <Loading />}
                <div className='container section faq'>
                    <div className='row'>
                        <div className="col-12 mb-5">
                            <div className="text-center d-flex flex-row mt-5">
                                <span className='rounded-pill mr-4 border-title-third text-primary-background' ></span>
                                <h1 className='dynamic-title font-weight-bold'>
                                    <span className='text-primary-background'>SERVICIOS</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{ __html: this.state.head }}
                        className='description-inner-html'
                    />
                    <div className='faq-accordion'>
                        <ul className='accordion'>
                            <Accordion allowMultipleExpanded={true}>
                                {this.state.items.map((item) => (
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>{item.title}</AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: item.description }}
                                            />
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </ul>
                    </div>
                </div>
                <ContactUs />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    configAccount: state.accountReducer,
});

export default connect(mapStateToProps, null)(Services);
