import React from 'react';
import PrimaryMenu from './primary-menu';
import SecondaryMenu from './secondary-menu';
import './styles.scss';
import UniqueMenu from './uniqueMenu';

const Menu = () => {
  if (window.socket_io_customer === 'remates_bonavista') {
    return <div className='menu'>
      <UniqueMenu />
    </div>;
  }

  return <div className='menu'>
    <SecondaryMenu />
    <PrimaryMenu />
  </div>;
};

export default Menu;
