import axios from 'axios';
import { Constants } from '../Constants';

export const userLogin = async (data) => {
  try {
    return await axios.post(`${Constants.BASE_URL}loginClient`, data);
  } catch (error) {
    throw error;
  }
};

export const userLoginWithCode = async (data) => {
  try {
    return await axios.post(`${Constants.BASE_URL}loginWithCode`, {
      loginCode: data,
    });
  } catch (error) {
    throw error;
  }
};

export const userLoginWithPin = async (data) => {
  try {
    return await axios.post(`${Constants.BASE_URL}loginWithPin`, data);
  } catch (error) {
    throw error;
  }
}

export const userLoginFB = async (data) => {
  try {
    return await axios.post(`${Constants.BASE_URL}loginFB`, data);
  } catch (error) {
    throw error;
  }
};

export const recoverPassword = async (data) => {
  try {
    return await axios.post(`${Constants.BASE_URL}recover_password`, data);
  } catch (error) {
    throw error;
  }
};

export const departaments = async () => {
  try {
    return await axios.get(`${Constants.BASE_URL}departaments`);
  } catch (error) {
    throw error;
  }
};

export const userRegister = async (data) => {
  try {
    return await axios.post(`${Constants.BASE_URL}register`, data);
  } catch (error) {
    throw error;
  }
};

export const userRegisterSimply = async (data) => {
  try {
    return await axios.post(`${Constants.BASE_URL}register_simply`, data);
  } catch (error) {
    throw error;
  }
}

export const getPageFAQ = async () => {
  try {
    return await axios.get(`${Constants.BASE_URL}public/pagefaq`);
  } catch (error) {
    throw error;
  }
};

export const getPageBuy = async () => {
  try {
    return await axios.get(`${Constants.BASE_URL}public/pageComprar`);
  } catch (error) {
    throw error;
  }
};

export const getPageSell = async () => {
  try {
    return await axios.get(`${Constants.BASE_URL}public/pageVender`);
  } catch (error) {
    throw error;
  }
};

export const getPageWayToPay = async () => {
  try {
    return await axios.get(`${Constants.BASE_URL}public/pageFormaPago`);
  } catch (error) {
    throw error;
  }
};

export const getPageTermAndConditions = async () => {
  try {
    return await axios.get(`${Constants.BASE_URL}public/pageTermAndConditions`);
  } catch (error) {
    throw error;
  }
};

export const getPageAboutUs = async () => {
  try {
    return await axios.get(`${Constants.BASE_URL}public/pageAboutUs`);
  } catch (error) {
    throw error;
  }
};

export const getPageSellBuy = async () => {
  try {
    return await axios.get(`${Constants.BASE_URL}public/pageSellBuy`);
  } catch (error) {
    throw error;
  }
};

export const getPageServices = async () => {
  try {
    return await axios.get(`${Constants.BASE_URL}public/pageServices`);
  } catch (error) {
    throw error;
  }
};

export const getMyShoppings = async (data) => {
  try {
    const httpOptions = {
      headers: {
        Authorization: data.token,
      },
    };
    return await axios.get(
      `${Constants.BASE_URL}myaccount/shoppings/${data.page}?auction=${data.auction}`,
      httpOptions
    );
  } catch (error) {
    throw error;
  }
};

export const getMyOffers = async (data) => {
  try {
    const httpOptions = {
      headers: {
        Authorization: data.token,
      },
    };
    return await axios.get(
      `${Constants.BASE_URL}myaccount/offers/${data.page}`,
      httpOptions
    );
  } catch (error) {
    throw error;
  }
};

export const getMyAccount = async (data) => {
  try {
    const httpOptions = {
      headers: {
        Authorization: data.token,
      },
    };
    return await axios.get(`${Constants.BASE_URL}myaccount`, httpOptions);
  } catch (error) {
    throw error;
  }
};

export const updateMyAccount = async (data) => {
  try {
    const httpOptions = {
      headers: {
        Authorization: data.token,
      },
    };
    return await axios.put(`${Constants.BASE_URL}myaccount`, data, httpOptions);
  } catch (error) {
    throw error;
  }
};

export const getLotsForLiquidate = async (data) => {
  try {
    const httpOptions = {
      headers: {
        Authorization: data.token,
      },
    };
    return await axios.get(
      `${Constants.BASE_URL}myaccount/liquidated_lots?type=${data.type}`,
      httpOptions
    );
  } catch (error) {
    throw error;
  }
};

export const calculateTotalsLiquid = async (data) => {
  try {
    const httpOptions = {
      headers: {
        Authorization: data.token,
      },
    };
    return await axios.post(
      `${Constants.BASE_URL}myaccount/calculate_totals_liquidate`,
      data,
      httpOptions
    );
  } catch (error) {
    throw error;
  }
};

export const verificarRUT = (rut) => {
  rut = rut.replace(/\D/g, '');
  let _suma = 0;
  let _multiplicarPor = [];
  _multiplicarPor[0] = 4;
  _multiplicarPor[1] = 3;
  _multiplicarPor[2] = 2;
  _multiplicarPor[3] = 9;
  _multiplicarPor[4] = 8;
  _multiplicarPor[5] = 7;
  _multiplicarPor[6] = 6;
  _multiplicarPor[7] = 5;
  _multiplicarPor[8] = 4;
  _multiplicarPor[9] = 3;
  _multiplicarPor[10] = 2;

  let _digitosRUT = [];
  let len = rut.length;
  for (let i = 0; i < len; i++) {
    _digitosRUT[i] = parseInt(rut[i]);
  }
  let len2 = _multiplicarPor.length;
  for (let i = 0; i < len2; i++) {
    let _auxMultiplicacion = _digitosRUT[i] * _multiplicarPor[i];
    _suma = _suma + _auxMultiplicacion;
  }
  let _digitoVerificador = 11 - (_suma % 11);
  if (_digitoVerificador == 11) _digitoVerificador = 0;
  if (_digitoVerificador == _digitosRUT[11]) return true;
  else return false;
};

function validation_digit(ci) {
  var a = 0;
  var i = 0;
  if (ci.length <= 6) {
    for (i = ci.length; i < 7; i++) {
      ci = '0' + ci;
    }
  }
  for (i = 0; i < 7; i++) {
    a += (parseInt("2987634"[i]) * parseInt(ci[i])) % 10;
  }
  if (a % 10 === 0) {
    return 0;
  } else {
    return 10 - a % 10;
  }
}

function clean_ci(ci) {
  return ci.replace(/\D/g, '');
}

export function verificarCI(ci) {
  ci = clean_ci(ci);
  // si todos los numeros son iguales retorno false
  if (ci.match(/(\d)\1{7,}/)) {
    return false;
  }
  var dig = ci[ci.length - 1];
  ci = ci.replace(/[0-9]$/, '');
  return (dig == validation_digit(ci));
}

export const verificateDNI = (dni) => {
  // validar dni expresion regular  ^[\d]{1,3}\.?[\d]{3,3}\.?[\d]{3,3}$  

  return true;
}