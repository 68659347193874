/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';

import './CarouselPhotos.scss';
import { Constants } from '../../Constants';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const CLIENT_WITH_ADMIN_LOGO = [
  'subastas_ya',
  'remates_morales',
  'rey_subastas',
  'remates_bonavista',
  'diego_diaz',
]

const CLIENTS_WITHOUT_LOGO = [
  'remates_mascheroni',
  'martin_gimenez',
  'arechaga'
]

class CarouselPhotos extends Component {

  scrollToAuctionsList = () => {
    document.getElementById('auctions-list').scrollIntoView();
  }

  getImageUrl = (image) => {
    if (image && image.includes(Constants.BASE_URL_IMAGES)) {
      return image;
    }
    return `${Constants.BASE_URL}${image}`;
  }

  render() {
    return (
      <Carousel indicators={false} className='carousel-home'>
        {this.props.photos.map((photo) => (
          <Carousel.Item interval={5000}>
            {photo.id_remate !== null ? (
              <Link to={`/subastas/${photo.id_remate}`}>
                <img
                  className='d-block w-100'
                  src={this.getImageUrl(photo.image)}
                />
                {
                  photo.is_principal ? (
                    <div className='details-for-banner'>
                      <div>
                        <label className='text-white text-center'>{photo.text_principal}</label>
                        <button className='primary-background primary-color rounded-pill font-weight-bold'>Participar</button>
                      </div>
                    </div>
                  ) : null
                }
              </Link>
            ) : photo.is_principal ? (
              this.props.sessionProps.account === null ?
                <Link to={`/ingresar`}>
                  <img
                    className='d-block w-100'
                    src={this.getImageUrl(photo.image)}
                  />
                  <div className='details-for-banner'>
                    <div>
                      {
                        CLIENTS_WITHOUT_LOGO.includes(window.socket_io_customer) ? null :
                          CLIENT_WITH_ADMIN_LOGO.includes(window.socket_io_customer) ?
                            <img alt='logo' src={Constants.BASE_URL + this.props.accountStore.config?.logo_admin} />
                            :
                            <img alt='logo' src={Constants.BASE_URL + this.props.accountStore.config?.logo_web} />
                      }
                      <label className='text-white text-center'>{photo.text_principal}</label>
                      <button className='primary-background primary-color rounded-pill font-weight-bold'>Ingresar</button>
                    </div>
                  </div>
                </Link>
                :
                <>
                  <img
                    className='d-block w-100'
                    src={this.getImageUrl(photo.image)}
                  />
                  <div className='details-for-banner'>
                    <div>
                      {
                        CLIENTS_WITHOUT_LOGO.includes(window.socket_io_customer) ? null :
                          CLIENT_WITH_ADMIN_LOGO.includes(window.socket_io_customer) ?
                            <img alt='logo' src={Constants.BASE_URL + this.props.accountStore.config?.logo_admin} />
                            :
                            <img alt='logo' src={Constants.BASE_URL + this.props.accountStore.config?.logo_web} />
                      }
                      <label className='text-white text-center'>{photo.text_principal}</label>
                      <button onClick={() => this.scrollToAuctionsList()}
                        className='primary-background primary-color rounded-pill font-weight-bold'>Participar</button>
                    </div>
                  </div>
                </>
            ) : (
              <img
                className='d-block w-100'
                src={this.getImageUrl(photo.image)}
              />
            )}
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionProps: state.userReducer,
  accountStore: state.accountReducer,
});

export default connect(mapStateToProps, null)(CarouselPhotos);