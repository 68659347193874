import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAccount } from "../../../../store/account";
import { QrCode, XLg, ChevronDown, Person, PinAngle, BoxArrowRight } from "react-bootstrap-icons";
import { connect } from "react-redux";
import { logoutAction } from "../../../../redux/actions/UserActions";
import CompleteData from "../../../modals/CompleteData";
import { useUser } from "../../../../store/user";
import TitleBox from '../../../../ui-components/title-box';
import TermAndConditions from "../../../../pages/dynamics/TermAndConditions";
import ValidateWpp from "../../../modals/ValidateWpp";
import RightArrowBig from "../../../../assets/icons/right-arrow-big.svg";
import './styles.scss';
import { Constants } from "../../../../Constants";

const UniqueMenu = (props) => {
	const { sessionProps, logout: logoutProps } = props;
	const accountStore = useAccount();
	const [collapsed, setCollapsed] = useState(false)
	const history = useHistory()
	const [collapseMenu, setCollapseMenu] = useState(false)
	const [showAccessCodebox, setShowAccessCodebox] = useState(false);
	const [showModalTermsAndCondition, setShowModalTermsAndCondition] = useState(false);

	const toggleNavbar = () => setCollapsed(!collapsed);

	const classOne = !collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
	const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

	useEffect(() => {
		if (history) {
			history.listen((location) => setCollapsed(false))
		}
	}, [history])

	const logout = useCallback(() => {
		logoutProps();
		history.push('/');
	}, [history, logoutProps])

	const wrapperRef = useRef(null);

	const useOutsideAlerter = (ref) => {
		useEffect(() => {
			/**
			 * Alert if clicked on outside of element
			 */
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setShowAccessCodebox(false);
				}
			}
			// Bind the event listener
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [ref]);
	};

	useOutsideAlerter(wrapperRef);

	const [code, setCode] = useState('');
	const userStore = useUser();

	useEffect(() => {
		userStore.getUserLogin();
		if (localStorage.getItem('showTermsAndCondition') && window.socket_io_customer === 'remates_morales') {
			setShowModalTermsAndCondition(true);
		}
	}, []);

	const handleLoginWithCode = () => {
		history.push('/');
		userStore.loginWithCode(code);
	};

	return (
		<>
			{
				window.socket_io_customer !== 'remates_cardozo' && sessionProps.account !== null && (!sessionProps.account.phone || sessionProps.account.phone === null || sessionProps.account.phone === '') ? (
					<CompleteData props={props} />
				) : null
			}
			{
				!showModalTermsAndCondition && sessionProps.account !== null && sessionProps.account.validation_status !== undefined && !sessionProps.account.validation_status ? (
					<ValidateWpp props={props} />
				) : null
			}
			<div className="unique-menu">
				{
					<div className="mobile" style={{
						width: '250px', margin: 'auto', textAlign: 'center'
					}}>
						<Link to="/"><img alt='logo' src={Constants.BASE_URL + accountStore.config?.logo_web}></img></Link>
					</div>
				}
				<div className={`container side-collapse-container box-items ${collapsed ? 'd-none' : 'd-flex '}`} style={{ fontWeight: '500' }}>
					{
						<div className="desktop" style={{
							width: '20%', alignItems: 'center', display: 'flex', justifyContent: 'center'
						}}>
							<Link to="/"><img alt='logo' src={Constants.BASE_URL + accountStore.config?.logo_web}></img></Link>
						</div>
					}
					<div style={{ gap: '1rem', padding: '1rem', width: '60%', justifyContent: 'center' }} className="d-md-flex d-none align-items-center">
						<Link to="/" className="link">
							<div className="item">Inicio</div>
						</Link>
						{accountStore?.config?.include_about_us ?
							<Link to="/nosotros" className="link">
								<div className="item">Nosotros</div>
							</Link> : null
						}
						{window.socket_io_customer === 'remates_bonavista' ?
							<Link to="/servicios" className="link">
								<div className="item">Servicios</div>
							</Link> : null}
						{accountStore?.config?.include_page_buy ?
							<Link to="/comprar" className="link">
								<div className="item">Comprar</div>
							</Link> : null
						}
						{accountStore?.config?.include_page_sell ?
							<Link to="/vender" className="link">
								<div className="item">Vender</div>
							</Link> : null
						}

						{accountStore?.config?.include_page_sell_buy ?
							<Link to="/comprar-vender" className="link">
								<div className="item">Comprar/Vender</div>
							</Link> : null
						}
						{accountStore?.config?.include_page_conditions
							? <Link to="/terminos-condiciones" className="link">
								<div className="item">Términos & condiciones</div>
							</Link>
							: null}
						{accountStore?.config?.include_page_faq
							? <Link to="/ayuda" className="link">
								<div className="item">Ayuda</div>
							</Link> : null}
						<Link to="/contacto" className="link">
							<div className="item">Contacto</div>
						</Link>
					</div>
					{
						!sessionProps?.account?.id ? (
							<div style={{
								width: '20%', alignItems: 'center', display: 'flex', justifyContent: 'center'
							}}>
								<Link to={'/ingresar'}>
									<button onClick={() => sessionProps?.account?.id && setCollapseMenu(!collapseMenu)} className="btn menu-button-name code-access-background">
										Ingresar
									</button>
								</Link>
							</div>
						) : (
							<div className="button-hello" style={{
								width: '20%', alignItems: 'center', display: 'flex'
							}} onClick={() => sessionProps?.account?.id && setCollapseMenu(!collapseMenu)}>
								<Link to={'#'} className='a-hello'>
									<button className="btn menu-button-name secondary-background">
										Hola, {sessionProps.account.name?.split(' ')[0]}
										<ChevronDown className="ml-2" />
										<ul style={{ top: '100%' }} class={`list-group position-absolute ${collapseMenu ? 'd-flex' : 'd-none'} `}>
											<Link to='/mi-cuenta/1/'><li class="d-flex rounded-0 list-group-item primary-background primary-color "><Person className="primary-color" size={20} /> Mi cuenta</li></Link>
											{/*<Link to={`/mi-cuenta/2/${sessionProps?.account?.id}/subastas`}><li class="d-flex rounded-0 list-group-item primary-background primary-color"><Cart className="primary-color" size={20} />Mis ofertas</li></Link>*/}
											<Link to={`/mi-cuenta/3/`}><li class="d-flex rounded-0 list-group-item primary-background primary-color"><PinAngle className="primary-color" size={20} />Mis compras</li></Link>
											<Link onClick={logout} to='#'><li class="d-flex rounded-0 list-group-item primary-background primary-color"><BoxArrowRight className="primary-color" size={20} />Cerrar sesi&oacute;n</li></Link>
										</ul>
									</button>
								</Link>
							</div>
						)
					}
					<button
						onClick={toggleNavbar}
						className={`${classTwo} d-md-none col-2`}
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
						href="#navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon">
							<i className="fas fa-bars" style={{ color: 'var(--text-color-with-primary)', fontSize: '26px' }}></i>
						</span>
					</button>
				</div>
				{
					collapsed &&
					<header role="banner" className={`${classOne} navbar navbar-fixed-top navbar-inverse collapse side-collapse-container ${collapsed ? 'out' : ''}  `} id="collapseExample">
						<div className="container">
							<div className="navbar-inverse side-collapse">
								<div className='d-flex flex-row'>
									<div className='col-10'>
										<nav role="navigation" className="navbar-collapse">
											<ul className="navbar-nav flex-column d-md-none mt-5" style={{ gap: '15px', fontSize: '12px', textAlign: 'left' }}>
												<li onClick={() => setCollapsed(false)} style={{ fontSize: '14px' }} className="nav-item p-relative">
													<Link to='/' className={`nav-link primary-color`}>
														Inicio
													</Link>
												</li>
												{accountStore?.config?.include_about_us ?
													<li onClick={() => setCollapsed(false)} style={{ fontSize: '14px' }} className="nav-item p-relative">
														<Link to="/nosotros" className="primary-color nav-link">
															<div className="item">Nosotros</div>
														</Link>
													</li> : null
												}
												{window.socket_io_customer === 'remates_bonavista' ?
													<li onClick={() => setCollapsed(false)} style={{ fontSize: '14px' }} className="nav-item p-relative">
														<Link to="/servicios" className="primary-color nav-link">
															<div className="item">Servicios</div>
														</Link>
													</li> : null}
												{accountStore?.config?.include_page_buy ?
													<li onClick={() => setCollapsed(false)} style={{ fontSize: '14px' }} className="nav-item p-relative">
														<Link to='/comprar' className={`primary-color nav-link`}>
															Comprar
														</Link>
													</li> : null}

												{accountStore?.config?.include_page_sell ?
													<li onClick={() => setCollapsed(false)} style={{ fontSize: '14px' }} className="nav-item p-relative">
														<Link to='/vender' className={`primary-color nav-link`}>
															Vender
														</Link>
													</li> : null}
												{accountStore?.config?.include_page_sell_buy ?
													<li onClick={() => setCollapsed(false)} style={{ fontSize: '14px' }} className="nav-item p-relative">

														<Link to="/comprar-vender" className="primary-color nav-link">
															<div className="item">Comprar/Vender</div>
														</Link>
													</li> : null
												}
												{accountStore?.config?.include_page_conditions ?
													<li onClick={() => setCollapsed(false)} style={{ fontSize: '14px' }} className="nav-item p-relative">
														<Link to="/terminos-condiciones" className="primary-color nav-link">
															<div className="item">Términos & condiciones</div>
														</Link>
													</li>
													: null}

												{accountStore?.config?.include_page_faq ?
													<li onClick={() => setCollapsed(false)} style={{ fontSize: '14px' }} className="nav-item p-relative">
														<Link to='/ayuda' className={`primary-color nav-link`}>
															Ayuda
														</Link>
													</li>
													: null}
												{
													!sessionProps?.account?.id ? (
														<li onClick={() => { setCollapsed(false); setShowAccessCodebox(true) }} style={{ cursor: 'pointer', fontSize: '14px' }}
															className="nav-item p-relative primary-color mt-2">
															&nbsp;<QrCode color='white' /> Código de acceso
														</li>
													) : null
												}
											</ul>
										</nav>
									</div>
									<div className='col-1 p-2'>
										<XLg onClick={() => setCollapsed(false)} className="primary-color" />
									</div>
								</div>
							</div>
						</div>
					</header>
				}

				{showAccessCodebox && (
					<div className='access-code-box'>
						<div className='dialog' ref={wrapperRef}>
							<TitleBox title='Ingresá tu código de acceso' />
							<div className='custom-input'>
								<input
									placeholder='Código de acceso'
									onChange={(e) => {
										setCode(e.target.value);
									}}
									onKeyPress={(e) => {
										if (e.key === 'Enter') {
											handleLoginWithCode();
										}
									}}
								/>
								<button
									className='action'
									onClick={() => {
										handleLoginWithCode();
									}}
								>
									<img
										src={RightArrowBig}
										alt=""
									/>
								</button>
							</div>
							<div className='separator'></div>
							<p>
								Si estás registrado y tenes problemas para ingresar solicitá un
								código de acceso
							</p>
						</div>
					</div>
				)}

				{
					showModalTermsAndCondition && (window.socket_io_customer === 'remates_morales' /* || window.socket_io_customer === 'amichelon_subastas' */) ? (
						< div className='modal-term-and-conditions'>
							<div className='dialog' ref={wrapperRef}>
								<div className="dialog-content">
									<TermAndConditions props={props} isModal={true} />
								</div>
								<div className="accept">
									<button
										className='text-white primary-background btn rounded-pill font-weight-bold'
										onClick={() => {
											setShowModalTermsAndCondition(false);
											localStorage.removeItem('showTermsAndCondition');
										}}
									>
										Aceptar términos y condiciones
									</button>
								</div>
							</div>
						</div>
					) : null
				}
			</div >
		</>
	);
};

const mapStateToProps = (state) => ({
	sessionProps: state.userReducer,
});

const mapDispatchToProps = (dispatch) => ({
	logout: () => {
		dispatch(logoutAction());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(UniqueMenu);
