import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const ItemAuction = ({
  img,
  imgMobile,
  lotsCount,
  auctionNumber,
  dateFrom,
  dateTo,
  name,
  type,
  presencial,
  finished
}) => {
  let link = '';
  presencial ? link = `subastas-presenciales/${auctionNumber}` : link = `subastas/${auctionNumber}`;

  if (window.socket_io_customer === 'remates_bonavista') {
    const DataBonavista = () => (
      <div className={`item-auction ${presencial ? 'item-auction-presencial' : ''} bonavista-style`}>
        <Link to={link}>
          <div className='row'>
            <div className={`col-12 without-padding`}>
              <div className={`content-box pl-3`}>
                <h5 className='font-weight-bold text-black'>
                  {name}
                </h5>
                {
                  !presencial && dateFrom &&
                  <div className='line text-black'>
                    <b>Inicio: </b> {dateFrom}
                  </div>
                }
                {
                  !presencial && dateTo &&
                  <div className='line text-black'>
                    <b>{finished ? 'Finalizó' : 'Cierre'}: </b> {dateTo}
                  </div>
                }
                {
                  presencial && (
                    <div className='line text-black'>
                      <b>{finished ? 'Finalizó' : 'Fecha'}: </b> {finished ? dateTo : dateFrom}
                    </div>
                  )
                }
                {
                  type !== 'column' &&
                  <div className='d-flex justify-content-end'>

                    <button className='secondary-background font-weight-bold text-white button-third-color primary-color btn'>
                      Ir a la subasta
                    </button>
                  </div>
                }
              </div>
            </div>
            <div className={`col-12 desktop`} >
              <img alt='' src={img} />
            </div>
            <div className={`col-12 mobile`} >
              <img alt='' src={imgMobile || img} />
            </div>
          </div>
        </Link>
      </div>
    )

    return <DataBonavista />
  }

  const Data = () => (
    <div className={`item-auction ${presencial ? 'item-auction-presencial' : ''}`}>
      <Link to={link}>
        <div className='row'>
          <div className={`col-12 desktop`} >
            <img alt='' src={img} />
          </div>
          <div className={`col-12 mobile`} >
            <img alt='' src={imgMobile || img} />
          </div>
          <div className={`col-12 without-padding`}>
            <div className={`content-box pl-3`}>
              <h5 className='font-weight-bold text-black'>
                {name}
              </h5>
              {
                !presencial && dateFrom &&
                <div className='line text-black'>
                  <b>Inicio: </b> {dateFrom}
                </div>
              }
              {
                !presencial && dateTo &&
                <div className='line text-black'>
                  <b>{finished ? 'Finalizó' : 'Cierre'}: </b> {dateTo}
                </div>
              }
              {
                presencial && (
                  <div className='line text-black'>
                    <b>{finished ? 'Finalizó' : 'Fecha'}: </b> {finished ? dateTo : dateFrom}
                  </div>
                )
              }
              {
                type !== 'column' &&
                <div className='d-flex justify-content-end'>

                  <button className='secondary-background font-weight-bold text-white button-third-color primary-color btn'>
                    Ir a la subasta
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </Link>
    </div>
  )

  return <Data />
};

export default ItemAuction;
